body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    background-color: red;
    opacity: 0.5;
  }
  50% {
    transform: scale(1.2);
    background-color: red;
    opacity: 1;
  }
  100% {
    transform: scale(1);
    background-color: red;
    opacity: 0.5;
  }
}

.pulsating-icon {
  animation: pulse 2s ease-in-out infinite;
}
